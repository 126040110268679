import React from "react";
import Header from "../Components/Header2";
import {
  ButtonGroup,
  Row,
  Col,
  Button,
  Container,
  Card,
} from "react-bootstrap";
import { saveAs } from "file-saver";
import printerexe from "../printerDrivers/BlackCopperBC-85ACPrinterDriverV1.0.zip";

import qmaticCounterSoftware from "../counterSoftware/QmaticCounterSoftware.rar";
import doorlockguide from "../img/doorlockguide.jpg";
import questions from "../Qmatic-Important-Questions.pdf";

// Guide
import psaGuide from "../PSA-Guide.pdf";
import counterGuideOld from "../Counter-Guide-Old.pdf";
import counterGuideNew from "../Counter-Guide-New.pdf";
import qmaticGuideComplete from "../Qmatic-Guide-Complete.pdf";
import qmaticMachineGuide from "../Qmatic-Machine-Guide.pdf";

const Downloads = () => {
  const downloadImage = () => {
    saveAs(doorlockguide, "doorlockguide.jpg");
  };
  const qmaticMachineCounterQuestions = () => {
    saveAs(questions, "Qmatic-Important-Questions.pdf");
  };

  // Guide
  const downloadpsaguide = () => {
    saveAs(psaGuide, "PSA-Guide.pdf");
  };
  const downloadcounterguideold = () => {
    saveAs(counterGuideOld, "Counter-Guide-Old.pdf");
  };
  const downloadcounterguidenew = () => {
    saveAs(counterGuideNew, "Counter-Guide-New.pdf");
  };
  const downloadqmaticcompleteguide = () => {
    saveAs(qmaticGuideComplete, "Qmatic-Guide-Complete.pdf");
  };
  const downloadqmaticMachineGuide = () => {
    saveAs(qmaticMachineGuide, "Qmatic-Machine-Guide.pdf");
  };

  const cardData = [
    {
      title: "Qmatic Machine Guide",
      guide: [
        {
          onClick: downloadqmaticMachineGuide,
          variant: "primary",
          text: "Qmatic Machine Guide",
        },
        {
          onClick: downloadqmaticcompleteguide,
          variant: "success",
          text: "Complete Guide",
        },
      ],
    },
    {
      title: "PSA / SSA Guide",
      guide: [
        { onClick: downloadpsaguide, variant: "primary", text: "PSA Guide" },
      ],
    },
    {
      title: "Counter Guide",
      guide: [
        {
          onClick: downloadcounterguideold,
          variant: "danger",
          text: "Counter Guide Old 888",
        },
        {
          onClick: downloadcounterguidenew,
          variant: "primary",
          text: "Counter Guide New",
        },
        
      ],
    },
    {
      title: "Thermal Printer Drivers",
      guide: [
        { href: printerexe, variant: "primary", text: "Thermal Printer Driver" },
      ],
    },
    
    {
      title: "Others",
      guide: [
        { onClick: downloadImage, variant: "info", text: "Door Lock Guide" },
        {
          href: qmaticCounterSoftware,
          variant: "secondary",
          text: "Qmatic Counter Software",
        },
      ],
    },
    {
      title: "FAQ",
      guide: [
        {
          onClick: qmaticMachineCounterQuestions,
          variant: "danger",
          text: "Questions About Qmatic Machine and Counter",
        },
      ],
    },
  ];
  return (
    <div>
      <Header title="Downloads" />
      <section className="text-center">
        <Container>
          <h3>Click on Button and Download File</h3>
          <div className="row downloads-section">
            {cardData.map((card, index) => (
              <Col xs={12} md={4} className="text-start mb-4" key={index}>
                <Card>
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    {card.guide.map((button, btnIndex) => (
                      <Button
                        key={btnIndex}
                        variant={button.variant}
                        onClick={button.onClick}
                      >
                        {button.text}
                      </Button>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Downloads;
